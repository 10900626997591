<template>
  <div ref="targetEl" :style="parentElementStyle">
    <slot v-if="options?.disabled" />

    <transition v-else-if="shouldRender" name="lazy-fade">
      <slot />
    </transition>
  </div>
</template>

<script setup lang="ts">
// This component is based off the following article:
// https://medium.com/js-dojo/lazy-rendering-in-vue-to-improve-performance-dcccd445d5f
import { useIntersectionObserver } from "@vueuse/core";
import { type PropType, ref, computed } from "vue";
import type { LazyLoadOptions } from "@/models/common";

const props = defineProps({
  options: {
    type: Object as PropType<LazyLoadOptions>
  },
  // minHeight acts as a "placeholder" until child is rendered
  minHeight: {
    type: Number,
    // 40 just seemed ok as a default
    default: 40
  },
  persist: {
    type: Boolean,
    default: false
  }
});

// Unrender the component in {n} seconds if it's out of viewbox
const UNRENDER_DELAY = 10_000;
const ROOT_MARGIN_IN_PX = 600;

const shouldRender = ref(false);
const targetEl = ref<HTMLDivElement | null>(null);
const fixedMinHeight = ref(0);
const unrenderTimer = ref<number | undefined>(undefined);
const renderTimer = ref<number | undefined>(undefined);

const parentElementStyle = computed(
  () =>
    props.options?.style || {
      minHeight: (fixedMinHeight.value || props.minHeight) + "px",
      display: "flex",
      alignItems: "center"
    }
);

useIntersectionObserver(
  targetEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      // perhaps the user re-scrolled to a component that was set to unrender. In that case stop the unrendering timer
      clearTimeout(unrenderTimer.value);
      // if we're doing unrendering lets add a waiting period of 100ms before rendering. If a component enters the viewport and also leaves it within 100ms it will not render at all. This saves work and improves performance when user scrolls very fast
      renderTimer.value = window.setTimeout(
        () => (shouldRender.value = true),
        100
      );
    } else {
      // if we want to render once and persist, return
      if (props.persist) {
        return;
      }
      // if the component was set to render, cancel that
      clearTimeout(renderTimer.value);
      unrenderTimer.value = window.setTimeout(() => {
        fixedMinHeight.value = targetEl.value?.clientHeight || 0;
        shouldRender.value = false;
      }, UNRENDER_DELAY);
    }
  },
  {
    // https://vueuse.org/core/useintersectionobserver/#type-declarations
    rootMargin: `${ROOT_MARGIN_IN_PX}px`
  }
);
</script>

<style scoped>
.lazy-fade-enter-active,
.lazy-fade-leave-active {
  transition: opacity 0.5s ease;
}

.lazy-fade-enter-from,
.lazy-fade-leave-to {
  opacity: 0;
}
</style>
